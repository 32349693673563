import { styled } from '@ori-ui/mui';

export const Root = styled('li')(({ theme }) => ({
  display: 'flex',
  flex: 'auto',
  flexFlow: 'row wrap',
  textAlign: 'left',
  gap: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    flex: 1,
    maxWidth: 300,
    flexFlow: 'column wrap',
    textAlign: 'center',
  },
}));

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  flexDirection: 'row',
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(2),
    flexDirection: 'column',
  },
}));
