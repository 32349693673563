import { Button, styled } from '@ori-ui/mui';

export const Root = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  height: 45,
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));
