import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { NOTIFICATION_BANNER_LINK_AREA } from './constants';
import { LinkArea, Text } from './styles';
import type { NotificationBannerProps } from './types';

export type NotificationBannerLinkAreaProps = Pick<
  NotificationBannerProps,
  'messageLinkTitle' | 'messageLinkUrl' | 'messageText'
>;
export const NotificationBannerLinkArea: FC<NotificationBannerLinkAreaProps> = ({
  messageLinkUrl,
  messageText,
  messageLinkTitle,
}) => {
  const { getTestId } = useTestId();

  return (
    <LinkArea
      data-testid={getTestId(NOTIFICATION_BANNER_LINK_AREA)}
      variant="body2"
      href={messageLinkUrl}
      target="_blank"
      underline="hover"
    >
      <Text variant="body2">{messageText}</Text>
      <Text variant="body2">{messageLinkTitle}</Text>
    </LinkArea>
  );
};
