/* istanbul ignore file */

/**
 * DO NOT EDIT!
 * This file was autogenerated by graphql-codegen.
 * Changes may cause incorrect behavior and will be lost when the code is regenerated.
 *
 * Run `npm run codegen` to regenerate.
 */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  JSON: { input: unknown; output: unknown };
  Void: { input: unknown; output: unknown };
};

/** Enum allowed search results */
export enum AddressMetadataAllowedSearchResults {
  Area = 'Area',
  City = 'City',
  Country = 'Country',
  District = 'District',
  Locality = 'Locality',
  Market = 'Market',
  Province = 'Province',
  Region = 'Region',
  Street = 'Street',
  ZipCode = 'ZipCode',
}

/** Enum address type */
export enum AddressType {
  Channel = 'Channel',
  Delivery = 'Delivery',
  Foreign = 'Foreign',
  Home = 'Home',
  Kyc = 'Kyc',
  Other = 'Other',
}

export enum ArticleType {
  /** Articles from Beauty Edit section */
  BeautyEdit = 'BeautyEdit',
  /** Articles related to products */
  RelatedToProduct = 'RelatedToProduct',
  /** Default value - not for use with API. Exists to detect errors in parsing values */
  Undefined = 'Undefined',
}

/** Channel to identify how we should notify user */
export enum BackInStockNotificationChannel {
  /** We will notify customer on provided email */
  Email = 'Email',
  /** We will notify customer on provided telephone */
  Sms = 'Sms',
}

/** Input to let user subscribe to when the product is back in stock */
export type BackInStockNotificationSubscriptionInput = {
  /** Email value for notificationChannel.Email */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /** The product code to watch */
  readonly itemCode: Scalars['String']['input'];
  /** Flag indicating the channel for notification */
  readonly notificationChannel: BackInStockNotificationChannel;
  /** Telephone value for notificationChannel.Telephone */
  readonly telephoneNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Background types */
export enum BackgroundType {
  /** Color as a background */
  Color = 'Color',
  /** Image as a background */
  Image = 'Image',
  /** No background */
  None = 'None',
  /** Video as a background */
  Video = 'Video',
}

export enum CatalogueOfferType {
  /** Special offer for buying product */
  Buy = 'Buy',
  /** Choose from products */
  Choose = 'Choose',
  /** Choose a product and choose another */
  ChooseAndChoose = 'ChooseAndChoose',
  /** Choose a product and get another */
  ChooseAndGet = 'ChooseAndGet',
  /** Get a product */
  Get = 'Get',
  /** No type of catalogue offer was specified */
  None = 'None',
}

export enum ChannelType {
  Mv7 = 'MV7',
  Website = 'Website',
}

export enum ChatBotMode {
  AlwaysEnabled = 'AlwaysEnabled',
  Disabled = 'Disabled',
  NonBusinessHoursOnly = 'NonBusinessHoursOnly',
}

/** Color type */
export enum ColorType {
  Brown = 'Brown',
  Green = 'Green',
  White = 'White',
}

/** Enum customer category */
export enum CustomerCategory {
  Anonymous = 'Anonymous',
  Consultant = 'Consultant',
  Employee = 'Employee',
  EndConsumer = 'EndConsumer',
  EndCustomer = 'EndCustomer',
  Invitation = 'Invitation',
  Other = 'Other',
}

/** eWallet type */
export enum EWalletType {
  Revolut = 'Revolut',
}

/** Editorial banner layout enumeration */
export enum EditorialBannerLayoutEnum {
  /** Banner with four media */
  FourMedia = 'FourMedia',
  /** Banner with headline */
  Headline = 'Headline',
  /** Banner with one media overlayed with text at the bottom */
  OneMediaCenterBottomText = 'OneMediaCenterBottomText',
  /** Banner with one media overlayed with text on the left */
  OneMediaOverlayLeftText = 'OneMediaOverlayLeftText',
  /** Banner with one media overlayed with text on the right */
  OneMediaOverlayRightText = 'OneMediaOverlayRightText',
  /** Banner with one media part and paragraph text */
  OneMediaParagraphText = 'OneMediaParagraphText',
  /** PLP banner with single media */
  OneMediaProductPromo = 'OneMediaProductPromo',
  /** Banner with paragraph text */
  ParagraphText = 'ParagraphText',
  /** Banner with quote */
  Quote = 'Quote',
  /** Banner with three media and right bottom text */
  ThreeMediaRightBottomText = 'ThreeMediaRightBottomText',
  /** Banner with two media parts */
  TwoMedia = 'TwoMedia',
  /** Banner with two media overlayed with text on the top */
  TwoMediaRightTopText = 'TwoMediaRightTopText',
  /** Banner with video */
  Video = 'Video',
}

/** Editorial banner title color enumeration */
export enum EditorialBannerTitleColorEnum {
  /** The black */
  Black = 'Black',
  /** The dark grey */
  DarkGrey = 'DarkGrey',
  /** The white */
  White = 'White',
}

/** Editorial banner title size enumeration */
export enum EditorialBannerTitleSizeEnum {
  H5 = 'H5',
  H6 = 'H6',
}

/** Editorial channel type */
export enum EditorialChannelType {
  Mobile = 'Mobile',
  Web = 'Web',
}

/** Editorial PLP banner size */
export enum EditorialPlpPromoBannerSize {
  Size1 = 'Size1',
  Size2 = 'Size2',
}

/** Facet filter */
export type EditorialPlpRowsFacetFilter = {
  /** Active children keys */
  readonly keys: ReadonlyArray<InputMaybe<Scalars['String']['input']>>;
  /** Facet name */
  readonly name: Scalars['String']['input'];
};

/** Feedback Benefit Banner type */
export enum FeedbackBenefitBannerType {
  Cashback = 'Cashback',
  ShareAndEarn = 'ShareAndEarn',
}

/** Enum gender */
export enum Gender {
  Man = 'Man',
  Other = 'Other',
  Woman = 'Woman',
}

/** Enum language type */
export enum LanguageType {
  Alternative = 'Alternative',
  Default = 'Default',
}

export enum MobileAppType {
  None = 'None',
  ToSell = 'ToSell',
  ToShop = 'ToShop',
}

/** Possible types of Navigation items */
export enum NavigationLinkType {
  /** All category */
  AllCategory = 'AllCategory',
  /** Bestseller products page */
  Bestsellers = 'Bestsellers',
  /** Bestseller products in category */
  BestsellersInCategory = 'BestsellersInCategory',
  /** Bottom part of the footer */
  Bottom = 'Bottom',
  /** Bottom part of the footer with copyright info */
  BottomCopyright = 'BottomCopyright',
  /** Links from the bottom part of the footer */
  BottomLinks = 'BottomLinks',
  /** Catalogue page */
  Catalogue = 'Catalogue',
  /** Catalogues page */
  Catalogues = 'Catalogues',
  /** Category */
  Category = 'Category',
  /** Catalogue for the current period */
  DigitalCatalogueCurrent = 'DigitalCatalogueCurrent',
  /** Catalogue for the next period */
  DigitalCatalogueNext = 'DigitalCatalogueNext',
  /** Focus page */
  FocusPage = 'FocusPage',
  /** Column of a footer */
  FooterColumn = 'FooterColumn',
  /** Item placed in footer's column */
  FooterColumnItem = 'FooterColumnItem',
  /** I Don't know yet link */
  IDontKnowYet = 'IDontKnowYet',
  /** Iconic products page */
  Icons = 'Icons',
  /** Iconic products in category */
  IconsInCategory = 'IconsInCategory',
  /** Inspiration section */
  Inspiration = 'Inspiration',
  /** Join rewards */
  JoinRewards = 'JoinRewards',
  /** Key ingredients page */
  KeyIngredients = 'KeyIngredients',
  /** Menu tiles */
  MenuTiles = 'MenuTiles',
  /** New products */
  New = 'New',
  /** No link type was specified for this item */
  None = 'None',
  /** Our applications */
  OurApps = 'OurApps',
  /** Outlet shop */
  OutletShop = 'OutletShop',
  /** Payment method */
  PaymentMethod = 'PaymentMethod',
  /** Available payment methods */
  PaymentMethods = 'PaymentMethods',
  /** Popular brand */
  PopularBrand = 'PopularBrand',
  /** Popular brands */
  PopularBrands = 'PopularBrands',
  /** Popular lookbook */
  PopularLookbook = 'PopularLookbook',
  /** Turkish QR code */
  Qrtr = 'QRTR',
  /** Rewards shop page */
  RewardsShop = 'RewardsShop',
  /** Sample shop page */
  SampleShop = 'SampleShop',
  /** Segment of subcategory */
  Segment = 'Segment',
  /** Subcategory */
  SubCategory = 'SubCategory',
  /** Support center */
  SupportCenter = 'SupportCenter',
  /** Top navigation */
  TopNavigation = 'TopNavigation',
  /** What's new in the category */
  WhatsNewInCategory = 'WhatsNewInCategory',
}

/** Possible types of navigations */
export enum NavigationType {
  /** About tiles section content */
  AboutTiles = 'AboutTiles',
  /** Footer section */
  Footer = 'Footer',
  /** Inspiration tiles content */
  InspirationTiles = 'InspirationTiles',
  /** Join section content */
  JoinTiles = 'JoinTiles',
  /** Shop section content */
  ShopSection = 'ShopSection',
  /** Top navigation bar items */
  TopNavigation = 'TopNavigation',
}

export enum NotificationBannerThemeType {
  Black = 'Black',
  Grey = 'Grey',
}

/** Olapic type enumeration */
export enum OlapicTypeEnum {
  /** Category widget */
  Category = 'Category',
  /** Front page widget */
  FrontPage = 'FrontPage',
  /** Gallery widget */
  Gallery = 'Gallery',
  /** Product detail page widget */
  ProductDetails = 'ProductDetails',
}

/** Products order strategies */
export enum PlpOrderBy {
  HighestDiscount = 'HighestDiscount',
  HighestPrice = 'HighestPrice',
  HighestRating = 'HighestRating',
  Latest = 'Latest',
  LowestPrice = 'LowestPrice',
  Recommended = 'Recommended',
}

/** Plp rows facet filter input */
export type PlpRowsFacetFilterInput = {
  /** Gets or sets the active children keys. */
  readonly keys?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  /** Gets or sets the facet name. */
  readonly name?: InputMaybe<Scalars['String']['input']>;
};

/** Product Label Domain Type */
export enum ProductLabelDomainType {
  /** Only Offer, New, SuperDeal */
  Deal = 'Deal',
  /** All of the ProductLabelType except Offer, New, SuperDeal, AddToBasketVisible */
  Ppa = 'PPA',
  /** Domain Type for AddToBasketVisible ProductLabelType */
  Shopping = 'Shopping',
}

export enum ProductListType {
  Favorite = 'Favorite',
  Personal = 'Personal',
  Private = 'Private',
  Wishlist = 'Wishlist',
}

/** Enum ProductListingBannerVideoType */
export enum ProductListingBannerVideoType {
  Mp4 = 'Mp4',
  None = 'None',
  Youku = 'Youku',
  Youtube = 'Youtube',
}

/** Relation types */
export enum ProductRelationEnum {
  RecentlyViewed = 'RecentlyViewed',
  Related = 'Related',
}

export enum ProductType {
  Bundle = 'Bundle',
  Composite = 'Composite',
  Fee = 'Fee',
  Product = 'Product',
  Sample = 'Sample',
}

/** ResponseMessageNewsletterSubsciptionsType */
export enum ResponseMessageNewsletterSubsciptionsType {
  /** Cleaned */
  Cleaned = 'Cleaned',
  /** New */
  New = 'New',
  /** Pending */
  Pending = 'Pending',
  /** Subscribed */
  Subscribed = 'Subscribed',
  /** Unsubscribed */
  Unsubscribed = 'Unsubscribed',
}

export enum SettingsClientType {
  OnlineReportingGrid = 'OnlineReportingGrid',
  WellnessClub = 'WellnessClub',
}

export enum SettingsFormatType {
  Json = 'Json',
  String = 'String',
  Xml = 'Xml',
}

/** Input data for updating personal code in share and earn. */
export type ShareAndEarnUpdatePersonalCodeInput = {
  /** The customer ID of the customer who wants to update the personal code. */
  readonly customerId: Scalars['String']['input'];
  /** The new personal code */
  readonly newPersonalCode: Scalars['String']['input'];
};

/** Live video show type */
export enum ShowType {
  /** Show is recorded */
  Ended = 'Ended',
  /** Show is running now */
  Live = 'Live',
  /** Show not started yet */
  Scheduled = 'Scheduled',
}

/** Types of social contact */
export enum SocialContactType {
  /** Email */
  Email = 'Email',
  /** Facebook */
  Facebook = 'Facebook',
  /** Phone */
  PhoneNumber = 'PhoneNumber',
  /** VKontakte */
  VKontakte = 'VKontakte',
  /** WeChat */
  WeChat = 'WeChat',
  /** WhatsUp */
  WhatsUpNumber = 'WhatsUpNumber',
}

/** Types of social media */
export enum SocialMediaType {
  /** Facebook */
  Facebook = 'Facebook',
  /** Instagram */
  Instagram = 'Instagram',
  /** Line */
  Line = 'Line',
  /** Odnoklassniki */
  Odnoklassniki = 'Odnoklassniki',
  /** Pinterest */
  Pinterest = 'Pinterest',
  /** QQ */
  Qq = 'QQ',
  /** Telegram */
  Telegram = 'Telegram',
  /** Twitter */
  Twitter = 'Twitter',
  /** VKontakte */
  VKontakte = 'VKontakte',
  /** WeChat */
  WeChat = 'WeChat',
  /** Weibo */
  Weibo = 'Weibo',
  /** YouTube */
  YouTube = 'YouTube',
  /** Youku */
  Youku = 'Youku',
}

/** Sponsor type */
export enum SponsorType {
  DirectSponsor = 'DirectSponsor',
}

/** Colors enum referring to FE storybook */
export enum StorybookColor {
  /** Shades/palette.blue[100] */
  Blue100 = 'Blue100',
  /** Shades/palette.brown[50] */
  Brown50 = 'Brown50',
  /** Shades/palette.brown[300] */
  Brown300 = 'Brown300',
  /** Shades/palette.brown[400] */
  Brown400 = 'Brown400',
  /** Palette - Main colors/palette.default.contrastText */
  DefaultContrastText = 'DefaultContrastText',
  /** Shades/palette.green[50] */
  Green50 = 'Green50',
  /** Shades/palette.green[400] */
  Green400 = 'Green400',
  /** Shades/palette.grey[50] */
  Grey50 = 'Grey50',
  /** Shades/palette.grey[900] */
  Grey900 = 'Grey900',
  /** InfoDark */
  InfoDark = 'InfoDark',
  /** Shades/palette.pink[50] */
  Pink50 = 'Pink50',
  /** Shades/palette.pink[300] */
  Pink300 = 'Pink300',
  /** PrimaryLight */
  PrimaryLight = 'PrimaryLight',
  /** Shades/palette.purple[50] */
  Purple50 = 'Purple50',
  /** Shades/palette.purple[200] */
  Purple200 = 'Purple200',
  /** Shades/palette.purple[400] */
  Purple400 = 'Purple400',
  /** Shades/palette.red[50] */
  Red50 = 'Red50',
  /** Palette - Main colors/palette.secondary.main */
  SecondaryMain = 'SecondaryMain',
  /** Typography/palette.text.primary */
  TextPrimary = 'TextPrimary',
  /** Typography/palette.text.secondary */
  TextSecondary = 'TextSecondary',
  /** Undefined value, its an error when this occurs */
  Undefined = 'Undefined',
  /** Shades/palette.yellow[50] */
  Yellow50 = 'Yellow50',
  /** Shades/palette.yellow[400] */
  Yellow400 = 'Yellow400',
}

/** Enum telephone type */
export enum TelephoneType {
  Business = 'Business',
  Fax = 'Fax',
  Home = 'Home',
  Mobile = 'Mobile',
}

/** Content vertical alignment */
export enum VerticalContentAlignment {
  Bottom = 'Bottom',
  Middle = 'Middle',
  Top = 'Top',
  Undefined = 'Undefined',
}

export enum VideoType {
  /** Video in MP4 file format */
  Mp4 = 'Mp4',
  /** Video hosted on Youku */
  Youku = 'Youku',
  /** Video hosted on YouTube */
  Youtube = 'Youtube',
}

/** Possible types of visitor segment */
export enum VisitorSegment {
  /** Anonymous */
  Anonymous = 'Anonymous',
  /** Consultant */
  Consultant = 'Consultant',
  /** Director and above */
  DirectorAndAbove = 'DirectorAndAbove',
  /** End customer */
  EndCustomer = 'EndCustomer',
  /** Manager */
  Manager = 'Manager',
  /** Member */
  Member = 'Member',
  /** New consultant */
  NewConsultant = 'NewConsultant',
  /** Not defined */
  NotDefined = 'NotDefined',
  /** Spo */
  Spo = 'Spo',
}
