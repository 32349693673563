import type { Theme } from '@ori-ui/mui';

import type { HeroBannerFragment } from '../../models';
import type { ResponsiveImageVariant } from '../../utils';
import { getResponsiveImageVariants } from '../../utils';
import { MAX_PRELOAD_IMAGE_COUNT } from './constants';

interface GetImagesInfoParams {
  heroBanners: HeroBannerFragment[];
  theme: Theme;
}

export const getImagesInfo = ({ heroBanners, theme }: GetImagesInfoParams): ResponsiveImageVariant[] => {
  // We need to copy the array to avoid altering the original array
  const heroBannersCopy = [...heroBanners];

  // eslint-disable-next-line max-params -- We need to pass all parameters to the callback
  return heroBannersCopy.reduce<ResponsiveImageVariant[]>((finalValue, currentValue, index, array) => {
    const { imageUrl, smallImageUrl } = currentValue;

    // We skip if we don't have imageUrl or we did create preload for this image url
    if (!imageUrl || finalValue.findIndex(({ url }) => url.startsWith(`${smallImageUrl ?? imageUrl}?`)) !== -1) {
      return finalValue;
    }

    // Make sure we dont overflow that maximum and break early
    if (index > MAX_PRELOAD_IMAGE_COUNT - 1) {
      array.splice(index);

      return finalValue;
    }

    const variants = getResponsiveImageVariants({
      desktopUrl: imageUrl,
      mobileUrl: smallImageUrl,
      backgroundColor: theme.palette.grey[100],
    });

    return [...finalValue, ...variants];
  }, []);
};
