import { Button, styled, Typography, type TypographyProps } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

import { HERO_BANNER_SECTION_MOBILE_HEIGHT } from '../../containers/HeroBannerSection/constants';

export const Root = styled('div', { shouldForwardProp: shouldForwardProp() })(({ theme }) => ({
  background: theme.palette.grey.A400,
  margin: 'auto',
  maxHeight: '100%',
  maxWidth: '100%',
  overflow: 'hidden',
  position: 'relative',
}));

export const StyledBanner = styled('a')(({ theme }) => ({
  color: theme.palette.common.white,
  display: 'block',
  height: HERO_BANNER_SECTION_MOBILE_HEIGHT,
  '&:hover, &:focus, &:active': {
    color: theme.palette.common.white,
  },
  [theme.breakpoints.up('sm')]: {
    height: '500px',
  },
}));

interface StyleProps {
  desktopColor: string;
  mobileColor: string;
}

export const TextBox = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<StyleProps>(({ theme, desktopColor, mobileColor }) => ({
  alignItems: 'center',
  bottom: theme.spacing(23),
  color: mobileColor,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'center',
  margin: theme.spacing(0, 6),
  position: 'absolute',
  width: `calc(100% - ${theme.spacing(12)})`,
  [theme.breakpoints.up('sm')]: {
    bottom: theme.spacing(12),
    color: desktopColor,
  },
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-end',
    bottom: '50%',
    margin: 0,
    maxWidth: 1186,
    padding: theme.spacing(0, 18),
    right: '50%',
    transform: 'translate(50%, 50%)',
    width: `calc(100% - ${theme.spacing(36)})`,
  },
}));

export const TagLine = styled(Typography)<Pick<TypographyProps, 'component'>>(({ theme }) => ({
  color: 'inherit',
  display: '-webkit-box',
  fontSize: '1.2rem',
  lineHeight: theme.typography.pxToRem(18),
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: 3,
  maxWidth: 450,
  overflow: 'hidden',
  textAlign: 'center',
  textTransform: 'uppercase',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  [theme.breakpoints.up('md')]: {
    width: 450,
  },
}));

export const Headline = styled(Typography)<Pick<TypographyProps, 'component'>>(({ theme }) => ({
  color: 'inherit',
  display: '-webkit-box',
  fontSize: '3rem',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: theme.typography.pxToRem(42),
  maxWidth: 450,
  overflow: 'hidden',
  textAlign: 'center',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  [theme.breakpoints.up('md')]: {
    fontSize: '5rem',
    lineHeight: theme.typography.pxToRem(70),
    width: 450,
  },
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: 450,
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
  height: 45,
  maxWidth: 450,
  minWidth: 150,
}));

export const Image = styled('img', {
  shouldForwardProp: shouldForwardProp(),
})(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  position: 'absolute',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
}));

export const DesktopImagePlaceholderWrapper = styled('div')(({ theme }) => ({
  display: 'none',
  height: 500,
  position: 'relative',
  svg: {
    objectFit: 'cover',
    position: 'absolute',
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}));

export const MobileImagePlaceholderWrapper = styled('div')(({ theme }) => ({
  display: 'block',
  height: 'calc(100vh - 214px)',
  position: 'relative',
  svg: {
    objectFit: 'cover',
    position: 'absolute',
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));
