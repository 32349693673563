import { styled } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

import type { AlignmentTypes, BannerOrientation } from '../../types';
import { BANNER_HEIGHT_BY_ORIENTATION, BANNER_WIDTH_BY_ORIENTATION } from './components/Banner/constants';

export const Root = styled('section', { shouldForwardProp: shouldForwardProp() })<{
  bannerAlignment: AlignmentTypes;
  bannerOrientation: BannerOrientation;
}>(({ theme, bannerAlignment, bannerOrientation }) => {
  const gridRow = bannerAlignment === 'left' ? `'banner products'` : `'products banner'`;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    padding: theme.spacing(0, 3),

    maxWidth: 1346,
    margin: theme.spacing(0, 'auto'),
    position: 'relative',
    gridTemplateAreas: `
        'title title'
        ${gridRow}
        `,
    gridTemplateColumns: bannerAlignment === 'left' ? '9fr 11fr' : '4fr 1fr',
    justifyContent: 'center',
    justifyItems: 'center',

    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 'auto'), // Larger screens: Apply margin
      display: 'grid',
      flexDirection: 'unset',
      gap: theme.spacing(6, 3),
      gridTemplateColumns:
        bannerAlignment === 'left'
          ? '7fr 13fr'
          : `4fr minmax(auto, ${BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].sm}px)`,
      padding: theme.spacing(0, 6),
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns:
        bannerAlignment === 'left'
          ? '5fr 15fr'
          : `4fr minmax(auto, ${BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].md}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns:
        bannerAlignment === 'left'
          ? `minmax(auto, ${BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].md}px) 1fr`
          : `4fr minmax(auto, ${BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].md}px)`,
    },
  };
});

export const Circle = styled('div')(({ theme }) => ({
  background: theme.palette.purple[100],
  borderRadius: '50%',
  height: 330,
  left: theme.spacing(-33),
  opacity: 0.2,
  position: 'absolute',
  top: 0,
  width: 330,
  zIndex: -1,

  [theme.breakpoints.up('sm')]: {
    height: 392,
    left: 'initial',
    right: -136,
    top: theme.spacing(-8),
    width: 392,
  },
}));

export const BannerWrapper = styled('div', { shouldForwardProp: shouldForwardProp() })<{
  bannerOrientation: BannerOrientation;
}>(({ theme, bannerOrientation }) => ({
  maxWidth: BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].sm,
  margin: '0 auto',
  height: BANNER_HEIGHT_BY_ORIENTATION[bannerOrientation].sm,
  position: 'relative',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    height: BANNER_HEIGHT_BY_ORIENTATION[bannerOrientation].md,
    margin: 0,
    maxWidth: BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].md,
  },
}));
