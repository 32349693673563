import { useEventCallback } from '@ori-ui/mui';

import { isClient } from '../../constants';
import { useSessionStorage } from '../../hooks';

export const NOTIFICATION_BANNER_STORAGE_KEY = 'showNotificationBanner';

export type UseBannerVisibilityToggleApi = { toggleBannerVisibility: () => void; showBanner: boolean };

export const useBannerVisibilityToggle: () => UseBannerVisibilityToggleApi = () => {
  const { value: showBanner, setValue: setShowBanner } = useSessionStorage(NOTIFICATION_BANNER_STORAGE_KEY, isClient());

  const toggleBannerVisibility = useEventCallback(() => setShowBanner((val) => !val));

  return { showBanner, toggleBannerVisibility };
};
