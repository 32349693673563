import { useToken } from '@ori/auth';
import { useServiceUrls } from '@ori/configuration-manager';
import { useEnvironment } from '@ori/environment';
import { ApiClientProvider as GlobalApiClientProvider } from '@ori/global-api-client-react';
import { useTranslationsWithNamespaces } from '@ori/i18n';
import type { UserSegmentationDataProviderProps } from '@ori/presentation-http';
import { HeadersProvider, UserSegmentationDataProvider } from '@ori/presentation-http';
import { ApiClientProvider as StaticApiClientProvider } from '@ori/static-api-client-react';
import type { FC, PropsWithChildren } from 'react';
import { APP_NAME } from '~/constants/app';

type SegmentationProviderProps = Pick<UserSegmentationDataProviderProps, 'atpClusterId' | 'customerTypeId'>;
export const SegmentationProvider: FC<PropsWithChildren<SegmentationProviderProps>> = ({ children, atpClusterId }) => {
  const { customerId, token } = useToken();
  const { globalApiUrl, staticApiUrl } = useServiceUrls(['globalApi', 'staticApi']);
  const { tenant } = useEnvironment();
  const { language } = useTranslationsWithNamespaces();

  return (
    <GlobalApiClientProvider
      baseUrl={globalApiUrl}
      language={language}
      tenant={tenant}
      token={token}
    >
      <StaticApiClientProvider
        baseUrl={staticApiUrl}
        language={language}
        tenant={tenant}
      >
        <UserSegmentationDataProvider atpClusterId={atpClusterId}>
          <HeadersProvider
            customerId={customerId}
            language={language}
            sourceAppName={APP_NAME}
            tenant={tenant}
            token={token}
          >
            {children}
          </HeadersProvider>
        </UserSegmentationDataProvider>
      </StaticApiClientProvider>
    </GlobalApiClientProvider>
  );
};
