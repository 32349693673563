import type { TypographyProps } from '@ori-ui/mui';
import { Button, styled, Typography } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

import type { AlignmentTypes, BannerOrientation } from '../../../../types';
import { BANNER_HEIGHT_BY_ORIENTATION, BANNER_WIDTH_BY_ORIENTATION } from './constants';
import type { BannerProps } from './types';

export const Root = styled('aside', { shouldForwardProp: shouldForwardProp() })<{
  alignment: AlignmentTypes;
  bannerOrientation: BannerOrientation;
}>(({ theme, alignment, bannerOrientation }) => ({
  alignSelf: 'stretch',
  borderRadius: theme.spacing(1),
  gridArea: 'banner',
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  order: 0,
  '&:hover': {
    '& $image': {
      filter: 'brightness(0.7)',
    },

    '& $headline': {
      color: theme.palette.common.white,
    },

    '& $button': {
      backgroundColor: 'rgba(255, 255, 255, .8)',
      color: theme.palette.common.white,
    },
  },
  maxWidth: BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].sm,
  margin: '0 auto',
  height: BANNER_HEIGHT_BY_ORIENTATION[bannerOrientation].sm,

  [theme.breakpoints.up('sm')]: {
    height: BANNER_HEIGHT_BY_ORIENTATION[bannerOrientation].md,
    margin: 0,
    maxWidth: BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].md,
    order: alignment === 'left' ? 0 : 1,
  },
}));

export const StyledBannerImage = styled('img', { shouldForwardProp: shouldForwardProp() })<
  Pick<BannerProps, 'alignment' | 'bannerOrientation'>
>(({ alignment, theme, bannerOrientation }) => ({
  height: '100%',
  maxWidth: theme.breakpoints.values.sm,
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].md,
  },
}));

export const Content = styled('div')(({ theme }) => ({
  bottom: 0,
  boxSizing: 'border-box',
  position: 'absolute',
  textAlign: 'center',
  width: '100%',
  padding: theme.spacing(6, 8),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3, 1),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6, 8),
  },
}));

export const HeadlineTypography = styled(Typography)<Pick<TypographyProps, 'component'>>(({ theme }) => ({
  display: '-webkit-box',
  fontFamily: 'SansCondensedOri, SansCondensedOriFallback, SansOri, SansOriFallback, sans-serif',
  fontSize: '3rem',
  lineHeight: '32px',
  overflow: 'hidden',
  textTransform: 'uppercase',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,

  [theme.breakpoints.up('sm')]: {
    fontSize: '3rem',
    lineHeight: '42px',
    WebkitLineClamp: 3,
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '1.4rem',
  letterSpacing: '0.3px',
  lineHeight: 1.7,
  marginTop: theme.spacing(2),
  padding: theme.spacing(2, 6),
}));

interface LinkProps {
  desktopColor: string;
  mobileColor: string;
}

export const Link = styled('a', { shouldForwardProp: shouldForwardProp() })<LinkProps>(
  ({ desktopColor, mobileColor, theme }) => ({
    color: mobileColor,
    [theme.breakpoints.up('sm')]: {
      color: desktopColor,
    },
  }),
);
