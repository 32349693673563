import { styled } from '@ori-ui/mui';

export const Root = styled('section')(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.offer.light,
  boxSizing: 'border-box',
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(6, 3),
  width: '100%',

  '& *': {
    boxSizing: 'inherit',
  },

  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(6),
  },
}));

export const BenefitsList = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  justifyContent: 'space-evenly',
  maxWidth: 1100,
  padding: theme.spacing(0, 3),
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    padding: 0,
  },
}));
