import { IS_SERVER } from '../../constants';
import { ServerSessionStorage } from './ServerSessionStorage';
import { SessionStoreRepository } from './SessionStoreRepository';

export const createSessionStoreRepository = () => {
  if (IS_SERVER) {
    return new SessionStoreRepository(new ServerSessionStorage());
  }

  return new SessionStoreRepository(window.sessionStorage);
};
