import { IconButton } from '@ori-ui/components';
import { Link, styled, Typography } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  position: 'fixed',
  bottom: 0,
  left: 0,
  zIndex: 101,
  padding: theme.spacing(3),
  background: theme.palette.default.main,
  color: theme.palette.primary.contrastText,
}));
export const LinkArea = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  color: theme.palette.primary.contrastText,
  gap: theme.spacing(2),
  '&:hover, &:focus, &:active': {
    color: theme.palette.primary.contrastText,
  },
}));
export const Text = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: 'inherit',
  fontSize: '1.3rem',
  fontWeight: theme.typography.fontWeightBold,
}));
export const CloseIconButton = styled(IconButton)(({ theme }) => ({
  alignSelf: 'flex-start',
}));
