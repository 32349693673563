import { Button, type TypographyProps, styled, Typography } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  display: 'grid',
  flexDirection: 'column',
  padding: theme.spacing(10, 0),
  margin: theme.spacing(0, 3),
  gap: theme.spacing(4),
}));

export const Title = styled(Typography)<Pick<TypographyProps, 'component'>>(({ theme }) => ({
  order: 1,
}));

export const Brands = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4, 2),
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr 1fr',
  justifySelf: 'center',
  order: 3,
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(10),
    maxWidth: 950,
    order: 2,
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridTemplateRows: 'auto',
  },
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
  order: 2,
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    order: 3,
  },
}));
export const ViewAllBrandsButton = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  borderColor: theme.palette.grey[600],
  color: theme.palette.text.primary,
  fontSize: '1.4rem',
  height: 45,
  textTransform: 'uppercase',
}));
