// istanbul ignore file -- Will be replaced with TopAreaBanner
import { useFeatureToggles } from '@ori-appshell/config';
import { useToken } from '@ori/auth';
import { useEnvironment } from '@ori/environment';
import { useLanguage } from '@ori/i18n';
import { useUserSegmentationData } from '@ori/presentation-http';
import { useGetTenantApplicationsFrontPageBenefitBanner } from '@ori/static-api-client-react';

import { HeroBannerFeature } from '../constants';
import { useFrontpage } from '../contexts';
import type { BenefitBannerFragment } from '../models';
import { VisitorSegment } from '../models';

interface UseBenefitBannerDataParams {
  isBenefitBannerHiddenCookie: boolean;
}
interface UseBenefitBannerData {
  data: BenefitBannerFragment['benefitBanner'];
  loading: boolean;
}

export const useBenefitBannerData = ({
  isBenefitBannerHiddenCookie,
}: UseBenefitBannerDataParams): UseBenefitBannerData => {
  const { isAnonymousUser } = useToken();
  const { tenant } = useEnvironment();
  const { language } = useLanguage();
  const { visitorSegment, loading } = useUserSegmentationData();
  const { isFeatureEnabled } = useFeatureToggles();
  const skip =
    loading ||
    isAnonymousUser ||
    isBenefitBannerHiddenCookie ||
    isFeatureEnabled(HeroBannerFeature.EnableTopAreaBanner);

  const { data, error } = useGetTenantApplicationsFrontPageBenefitBanner(
    tenant,
    {
      lang: language,
      visitorSegment: visitorSegment ?? VisitorSegment.Anonymous,
    },
    {
      swr: {
        enabled: !skip,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      },
    },
  );

  const { benefitBanner } = useFrontpage();

  return {
    data: {
      messageTitle: isAnonymousUser ? benefitBanner?.messageTitle : data?.messageTitle,
      messageUrl: isAnonymousUser ? benefitBanner?.messageUrl : data?.messageUrl,
    },
    loading: !skip && data === undefined && error === undefined,
  };
};
